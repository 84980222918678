<template>
	<div class="bottom">
		<div class="bottom-top">
			<div class="logo">
				<img src="../../../../assets/logo.png" />
				<span style="padding: 0 20px 0 15px">东修西修</span>
				<span>不如一修</span>
			</div>

			<div class="foot">
				<div class="foot-bottom__line"></div>
				<div class="foot-bottom">
					<div class="main">
						<div class="foot-bottom__img fl"> <img src="https://www.yxgwlkj.cn/style/images/wx.jpg"
								alt="" />
							<p>官方微信公众号</p>
						</div>
						<div class="foot-bottom__img fl"> <img src="https://www.yxgwlkj.cn/style/images/xcx1.jpg"
								alt="" />
							<p>客户端微信小程序</p>
						</div>
						<div class="foot-bottom__img fl"> <img src="https://www.yxgwlkj.cn/style/images/xcx2.jpg"
								alt="" />
							<p>师傅端微信小程序</p>
						</div>
						<div class="foot-bottom__fen fl" style="width: 200px;">
							<div class="foot-bottom__fen_a1 fl" style="color: #999999;font-size: 14px;text-align: start;"> 
								<div style="padding-bottom: 10px;padding-left: 40px;font-size: 16px;">联系我们</div>
								<div style="padding: 5px 0;">客服电话：0591-8860-1860</div>
								<div style="line-height: 20px;">办公地址：福建省福州市闽侯县闽都大庄园浪琴路125栋</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
		<div style="color: #999999;cursor: pointer;" @click="jump">
			福建依修网络科技有限公司 yxgwlkj.cn 备案/许可证号：闽ICP备19013575号-1
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		methods:{
			jump(){
				window.location.href = "https://beian.miit.gov.cn/"
			}
		}
	}
</script>

<style>
	.bottom {
		width: 100%;
		height: 300px;
		background: #EBEBEB;
		margin-top: 80px;
	}

	.bottom-top {
		width: 925px;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}

	.logo {
		display: flex;
		align-items: center;
		color: #19B955;
		font-weight: bold;
		margin-right: 50px;
	}
	.main {
	}
	.foot {
		padding: 60px 0;
	}
	.foot-bottom__img {
		float: left;
		width: 102px;
		margin-right: 45px;
		background: #fff;
	}
	.foot-bottom__img img {
		width: 102px;
		height: 102px;
	}
	.foot-bottom__img > p{
		font-weight: bold;
		height: 20px;
		line-height: 20px;
		font-size: 12px;
		text-align: center;
		color: #aeaeae;
	}
	.foot-bottom__fen {
		float: left;
	}
</style>
