<template>
    <div>
		<div class="title">
			<img src="../../../assets/newsTit.png" />
		</div>
		<div style="height: 30px;"></div>
		<div class="list" v-for="(item,index) in news" :key="index">
			<div><img :src="item.image"></div>
			<div class="textCon">
				<div>{{item.title}}</div>
				<div class="content">{{item.keys}}</div>
				<div class="more">
					<router-link :to="{path:'/detail',query:{id:item.id}}">More</router-link>
				</div>
				<div class="time">{{item.add_time}}</div>
			</div>
		</div>
		<div>
			
		</div>
				<el-pagination
				  :current-page="pageConfig.currentPage"
				  :page-sizes="pageConfig.pageShowNumArr"
				  :page-size="pageConfig.pageShowNum"
				  :total="pageConfig.total"
				  background
				  layout="total, sizes, prev, pager, next, jumper"
				  @size-change="handleSizeChange"
				  @current-change="handleCurrentChange"
				  style="margin-top:20px"
				/>	
		
		<bottom/>
    </div>
</template>

<script>
	import bottom from '../Home/components/bottom.vue'
	import axios from 'axios';
	import * as api from '@/api/api.js'
	export default {
		components:{
			bottom
		},
		data() {
			return {
				news:'',
				pageConfig:{
					pageShowNum:10,
					currentPage:1,
					type_id:1,
					total:'',
				}
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			getData(){
				var that = this;
				axios.post(api.url+api.article, this.pageConfig).then(function(res) {
					that.news = res.data.data.list;
					that.pageConfig.total = res.data.data.total
				})
			},
			handleSizeChange(val){
				this.pageConfig.pageShowNum = val;
				this.getData()
			},
			handleCurrentChange(val){
				this.pageConfig.currentPage = val;
				this.getData()
			}
		}
	}
</script>

<style>
	.title{
		width: 100%;
	}
	.title img {
		width: 100%;
		height: 100%;
	}
	.list {
		width: 1200px;
		height: 200px;
		padding: 30px 20px;
		margin: 0 auto;
		display: flex;
		border-bottom: 1px solid #f3f3f3;
	}
	.list>div:first-child {
		width: 326px;
		height: 200px;
		margin-right:30px ;
	}
	.list>div:nth-child(2){
		width: 850px;
		text-align: start;
	}
	.textCon {
		position: relative;
	}
	.textCon > div:first-child{
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 30px;
	}
	.content {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		word-wrap: break-word;
		word-break: normal;
		height: 108px;
		-webkit-line-clamp: 5;
	}
	.list>div:first-child > img {
		width: 100%;
		height: auto;
		/* object-fit: cover; */
		
		
		
		/* width:expression(document.body.clientWidth>300?”300px”:”auto”); */
		
		
	}
	.more {
		width: 82px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #00BB2C;
		border-radius: 3px;
		position: absolute;
		bottom: 0;
		left: 0;
		cursor: pointer;
	}
	.more a {
		color: #00BB2C;
	}
	.time {
		position: absolute;
		bottom: 0;
		right: 0;
		color: #999999;
	}
</style>